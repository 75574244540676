import UsageRecord from './UsageRecord.vue'
import axios from 'axios'
import jwt_decode from "jwt-decode";
export default{
    components:{
        UsageRecord
    },
    data(){
        return {
            activeName: 'first',
            perRecordList:[],
            currentPage1:1,
            page1:0,
            per_page1:10,
            count1:0,
            allRecordList:[],
            currentPage2:1,
            page2:0,
            per_page2:10,
            count2:0,
            activeName1: '0',
            user:'',
            userMoreShow:false,
            detailList:[],
            fzhRecordShow:false,
            username:'',
            userId:'',
            usernameListShow:false,
            username_lists:[],
            listNull:false
        }
    },
    created(){
        this.perRecordSearch()
        var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
        if(decoded.admin == "1"){
            this.fzhRecordShow = true
        }else {
            this.fzhRecordShow = false
        }
    },
    methods: {
        handleClick(tab) {
            if(tab.name == 'first'){
                this.perRecordSearch()
                this.userMoreShow=false
            }else if(tab.name == 'second') {
                this.allRecordSearch()
                this.userMoreShow=false
            }
        },
        // 个人消费记录查询
        perRecordSearch(val){
            var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1+ '/expense/personal_record',{
              params:{
                  user_id: val ? val : decoded.uid,
                  page:this.page1,
                  per_page: this.per_page1
              }
            })
            .then(res=>{
                if(res.data.status_code == 0){
                    if(val == undefined){
                        this.count1 = res.data.count
                        this.perRecordList = res.data.data.expenditure
                    }else {
                        this.count2 = res.data.count
                        this.detailList = res.data.data.expenditure
                    }
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.per_page1 = val
            this.perRecordSearch()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page1 = val
            this.perRecordSearch()
        },
        //公司消费记录查询
        allRecordSearch(){
            var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1+ '/expense/all_record',{
              params:{
                  user_id:decoded.uid,
                  page:this.page2,
                  per_page: this.per_page2
              }
            })
            .then(res=>{
                if(res.data.status_code == 0){
                    this.count2 = res.data.total_record
                    this.allRecordList = res.data.data
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 分页2
        handleSizeChange2(val) {
            console.log(`每页 ${val} 条`);
            this.per_page2 = val
            this.allRecordSearch()
        },
        handleCurrentChange2(val) {
            console.log(`当前页: ${val}`);
            this.page2 = val
            this.allRecordSearch()
        },
        // 用户名模糊查询
        searchName(val){
            if(val == '') {
                this.usernameListShow = false
            }else {
                this.usernameListShow = true
                var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
                axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                axios.get(this.GLOBAL.BASE_URL1 + '/expense/search_name',{
                    params:{
                        user_id:decoded.uid,
                        user_name:val
                    }
                })
                .then(res=>{
                    if(res.data.status_code == 0 ){
                        if(res.data.data.length == 0){
                            this.listNull = true
                            this.username_lists = []
                        }else {
                            this.listNull = false
                            this.username_lists = res.data.data
                        }
                    }
                })
                .catch(error=>{
                    console.log(error);
                })
            }
            
        },
        sureUsername(val){
            this.usernameListShow = false
            this.username_lists = []
            this.page2 = 0
            this.per_page2 = 10
            this.username = val.username
            this.userId = val.user_id
        },
        // 按用户名查询
        fifterUser(){
            var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/expense/search_id',{
                params:{
                    id :decoded.uid,
                    user_id:this.userId,
                    page:this.page2,
                    per_page: this.per_page2
                }
            })
            .then(res=>{
                if(res.data.status_code == 0){
                    this.count2 = res.data.total_record
                    this.allRecordList = res.data.data
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        search(){
            if(this.username == '' && this.userId == ''){
                this.allRecordSearch()
            }else {
                if(this.userId == '') {
                    this.$message({
                        type: 'warning',
                        message: '请选择正确的用户名！',
                        showClose: true,
                        center: true
                    });
                }else {
                    this.fifterUser() 
                }
            }
        },
        reset(){
            this.username = ''
            this.userId = ''
            this.allRecordSearch()
            this.usernameListShow = false
            this.username_lists = []
        },
    }
}